@mixin for-phone-only {
  @media (max-width: 640px) {
    @content;
  }
}

@mixin for-desktop-only {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin for-tab-and-mobile {
  @media (max-width: 768px) {
    @content;
  }
}
