@use 'common-ui/src/lib/shared-styles/breakpoints';
@import '@mri/scss-all/scss/variables/base/_colors.scss';
@import 'libs/shared/common-ui/src/lib/shared-styles/shared.scss';

@include apply-default-color-theme-light();

.reportcontainerdiv {
  height: 100%;
  overflow: clip;
}

.powerbiReport {
  height: 100%;
}

.dsg-center-contents {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

/* The filter tags when summarized & not summarized are of a different height so 
we are adding these styles to make it consistent. */
.filter * .k-chip {
  height: 35.6px;
}

/* If there are no tags in a filter the filters goes back to the normal height
which causes inconsistency in the UI.*/
/* The multiselect takes up 2 lines of space, one for search and another for tags, 
which is not ideal as the kendo default only takes up one so adding a display flex 
to make sure it appears on the same line */
/* The input element is only wide enough to write 3 characters so increasing the 
width to 5em */
.filter kendo-multiselect {
  min-height: 54px;
  align-items: center;
  input {
    height: inherit !important;
    width: 5em !important;
  }

  .k-input-values {
    display: flex;
  }

  .k-actionsheet-title {
    display: none;
  }
}

/* In Tab and mobile mode the selection is done via action sheet so we 
are resetting the width */
@include breakpoints.for-tab-and-mobile {
  .filter kendo-multiselect input {
    width: 1em !important;
  }
}

/* The multi select doesnt show the placeholder accurately when search and tags are in 
separate line */
.filter-list-master .k-input-values {
  flex-direction: column;
  gap: 0 !important;
}

.contextmenu-item {
  display: flex;
  align-items: center;
  column-gap: 4px;
  max-width: 25em;
  min-width: 10em;

  &.add-menu {
    color: mri-blue(60);
  }

  .k-menu-expand-arrow {
    transform: rotate(180deg);
    margin-inline-start: unset !important;
  }

  .kendo-menu-arrow-alignment {
    padding-inline-start: 32px !important;
    padding-inline-end: unset !important;
    max-width: inherit;
  }

  .contextmenu-link-text {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .contextmenu-link-description {
    color: mri-gray(60);
    font-size: 12px;
  }
}
